import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { debounce } from "@mui/material";
import {
	CustomDialog,
	HorizontalDivider,
	IconComponent,
	Selector,
	icon,
} from "../../../../../../../../../common/components";
import styles from "./LinkCreationDialog.module.css";
import { translate } from "../../../../../../../../../common/providers";
import { isNonEmptyArray } from "../../../../../../../../../common/utils";
import { useApi } from "../../../../../../../../../common/hooks";
import { ProjectDocumentService, SearchCardService } from "../../../../../../../../../api";
import DocumentAutoCompleteSearch from "../../../../../../../../../common/components/autocomplete-text-field/document-autocomplete-search/DocumentAutocompleteSearch";
import ContentAutoCompleteSearch from "../content-autocomplete-search/ContentAutocompleteSearch";
import { OPERATORS } from "../../../../../../../constants/constants";
import { linkConversion, translateEnumLinks } from "../../../../../../../utils/utils";
import ContentToLink from "../content-to-link/ContentToLink";

const page = 0;
const limit = 20;

const LinkCreationDialog = ({ open, onClose, onSubmit }) => {
	const [linkType, setLinkType] = useState("");
	const linkTypes = useSelector(({ srDocument }) => srDocument.linkTypes);
	const projectId = useSelector(({ srDocument }) => srDocument.projectId);
	const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
	const [isLoadingContents, setIsLoadingContents] = useState(false);
	const [isSelectedResult, setIsSelectedResult] = useState(false);
	const [searchDocument, setSearchDocument] = useState([]);
	const [searchContents, setSearchContents] = useState({});
	const [searchDocumentInputValue, setSearchDocumentInputValue] = useState("");
	const [searchContentInputValue, setSearchContentInputValue] = useState("");
	const [selectedDocument, setSelectedDocument] = useState(null);
	const [selectedContent, setSelectedContent] = useState(null);
	const [searchDebouncedDocument, setSearchDebouncedDocument] = useState("");
	const [searchDebouncedContent, setSearchDebouncedContent] = useState("");
	const { call: searchDocs } = useApi(ProjectDocumentService.search);
	const { call: searchContent } = useApi(SearchCardService.search);

	useEffect(() => {
		if (!open) {
			setSearchDocumentInputValue("");
			setSearchContentInputValue("");
			setLinkType("");
			setSelectedContent(null);
			setSelectedDocument(null);
			setSearchDocument([]);
			setSearchContents({});
			setIsSelectedResult(false);
		}
	}, [open]);

	const handleSelectType = (e) => {
		const { value } = e.target;
		setLinkType(value);
	};

	/** Document Search */
	useEffect(() => {
		if (
			searchDebouncedDocument &&
			searchDebouncedDocument === searchDocumentInputValue &&
			searchDebouncedDocument.length > 1
		) {
			searchDocs({ projectId }, { name: searchDebouncedDocument })
				.then((data) => setSearchDocument(data))
				.catch(console.error)
				.finally(() => setIsLoadingDocuments(false));
		}
	}, [projectId, searchDocs, searchDebouncedDocument, searchDocumentInputValue]);
	const debouncedSearch = debounce((name) => {
		setSearchDebouncedDocument(name);
	}, 500);
	const handleSearchDocument = (name) => {
		setSearchDocumentInputValue(name);
		if (!name) {
			setSearchDocument([]);
			setIsLoadingDocuments(false);
			return;
		}
		setIsLoadingDocuments(true);
		debouncedSearch(name);
	};
	const handleDocumentSelection = (_, id) => {
		setSelectedDocument(id);
	};
	const handleResetDocument = () => {
		setSelectedDocument(null);
	};

	/** Content Search */
	useEffect(() => {
		if (searchDebouncedContent === searchContentInputValue) {
			if (searchDebouncedContent && searchDebouncedContent.length > 2) {
				const filters = {
					separator: OPERATORS.AND,
					Type: "InformationSearchFilterDTO",
					isFilterTocOut: false,
					keywordFilters: {
						filters: [{ keywords: [searchDebouncedContent], operator: "IS_EXACTLY", matchAll: true }],
					},
				};
				const regex = /^(req_|info_)(\d+)$/i;
				const match = searchDebouncedContent.match(regex);
				if (match) {
					const [, , numbers] = match;
					filters.informationIds = { elements: [Number.parseInt(numbers, 10)] };
					delete filters.keywordFilters;
				}
				if (selectedDocument) {
					filters.documents = { is: true, matchAll: true, elements: [selectedDocument] };
				}
				searchContent({ projectId }, filters, page, limit)
					.then((data) => setSearchContents(data))
					.catch(console.error)
					.finally(() => setIsLoadingContents(false));
			} else {
				setSearchContents({});
				setIsLoadingContents(false);
			}
		}
	}, [projectId, searchContent, searchDebouncedContent, searchContentInputValue, selectedDocument]);
	const debouncedSearchContents = debounce((name) => {
		setSearchDebouncedContent(name);
	}, 500);
	const handleSearchContent = (name) => {
		setSearchContentInputValue(name);
		setIsLoadingContents(true);
		debouncedSearchContents(name);
	};
	const handleContentSelection = (content) => {
		setSelectedContent(content);
		setIsSelectedResult(true);
	};
	const handleResetContent = () => {
		setSelectedContent(null);
	};

	const optionText = translate("smart-review.cmp-details.option-panel.link-tab.create-link.document").split(" - ");

	const handleSubmit = () => {
		const { informationId } = selectedContent;
		onSubmit({ linkType, linkId: informationId });
	};

	return (
		<CustomDialog
			disableBackdropClick
			isForm
			open={open}
			submitDisabled={!linkType || !selectedContent}
			title={translate("smart-review.cmp-details.option-panel.link-tab.create-link")}
			onClose={onClose}
			onSubmit={handleSubmit}
		>
			<div className={styles.block}>
				<div>
					<span className={styles.title}>
						{translate("smart-review.cmp-details.option-panel.link-tab.create-link.link-type")}
					</span>
					<IconComponent
						className={styles.infoIcon}
						color="var(--color-red)"
						icon={icon.faAsterisk}
						size="2xs"
					/>
				</div>
				<Selector
					fullWidth
					autoTranslate="no"
					className={styles.formControl}
					disabled={!isNonEmptyArray(linkTypes)}
					itemRenderer={(label) => (
						<span>
							<IconComponent
								className={styles.linkIcon}
								color={linkConversion(label)?.color}
								icon={icon.faLink}
								size="xs"
							/>
							{translateEnumLinks(label)}...
						</span>
					)}
					items={linkTypes || []}
					label={translate("smart-review.cmp-details.option-panel.link-tab.create-link.link-type.select")}
					labelClassName={styles.linkSelector_label}
					value={linkType}
					valueRenderer={(label) => translateEnumLinks(label)}
					onChange={handleSelectType}
				/>
			</div>
			<HorizontalDivider className={styles.divider} />
			<div className={styles.block}>
				<div>
					<span className={styles.title}>{optionText[0]}</span>
					<span> - {optionText[1]}</span>
				</div>
				<div>
					<DocumentAutoCompleteSearch
						documents={searchDocument?.contents || []}
						emptyStateLabel={translate("common:document-autocomplete-search.empty-state")}
						error={false}
						inputValue={searchDocumentInputValue}
						isLoading={isLoadingDocuments}
						label={translate("smart-review.cmp-details.option-panel.link-tab.create-link.document.select")}
						onReset={handleResetDocument}
						onSearch={handleSearchDocument}
						onSelect={handleDocumentSelection}
					/>
				</div>
			</div>
			<div className={styles.block}>
				<div>
					<span className={styles.title}>
						{translate("smart-review.cmp-details.option-panel.link-tab.create-link.content")}
					</span>
					<IconComponent
						className={styles.infoIcon}
						color="var(--color-red)"
						icon={icon.faAsterisk}
						size="2xs"
					/>
				</div>
				{(!selectedContent && (
					<div>
						<ContentAutoCompleteSearch
							contents={searchContents?.details?.contents || []}
							emptyStateLabel={translate(
								"smart-review.cmp-details.option-panel.link-tab.create-link.content.no-result"
							)}
							error={false}
							inputValue={searchContentInputValue}
							isLoading={isLoadingContents}
							label={translate(
								"smart-review.cmp-details.option-panel.link-tab.create-link.content.select"
							)}
							onReset={handleResetContent}
							onSearch={handleSearchContent}
							onSelect={handleContentSelection}
						/>
					</div>
				)) || (
					<ContentToLink
						content={selectedContent}
						isSelectedResult={isSelectedResult}
						onClose={handleResetContent}
					/>
				)}
			</div>
		</CustomDialog>
	);
};

export default LinkCreationDialog;
